<template>
    <div>
        <div>
            <b-tabs pills v-model="tab" class="border-2">
                <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
            </b-tabs>
        </div>
        <ek-table
            :columns="tab == 0 ? columns : newColumns"
            :items="universities"
            @delete-selected="deleteUni"
            @details="detailsAppPanel"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span> {{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.type" slot-scope="{ value }">
                <span v-if="value == 1">مرحلة التعليم المدرسي </span>
            </template>
            <template v-if="tab == 1" slot="items.id" slot-scope="{ value }">
                <b-button
                    size="sm"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    style="padding: 2px 6px !important"
                    @click="goToDetails(value)"
                >
                    <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            tab: 0,
        };
    },
    components: {},
    computed: {
        ...mapState({
            columns: (state) => state.university.columns,
            newColumns: (state) => state.university.newColumns,
        }),
        ...mapGetters(["universities"]),
    },
    methods: {
        ...mapActions(["getUniversityList", "getFacultyName", "getGetUnRead"]),
        detailsAppPanel({ row }) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_UNIVERSITY_DTO", row);
        },
        deleteUni(e) {
            this.$store.dispatch("deleteUniversities", e);
        },
        goToDetails(id) {
            this.$router.push(`/admin/university/${id}`);
        },
    },
    created() {
        this.getUniversityList(this.tab);
        this.getFacultyName(this.tab);
        this.getGetUnRead();
    },
    watch: {
        tab: function (newTab) {
            if (newTab === 0) {
                this.getUniversityList(0);
                this.getFacultyName(0);
            } else {
                this.getUniversityList(1);
                this.getFacultyName(1);
            }
        },
    },
};
</script>
